import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SpaceBar = styled.hr<{ dark: boolean }>`
  background-color: ${(props) =>
    props.dark ? props.theme.colors.WhiteSmoke : props.theme.colors.Dark};
  height: 1px;
  width: 100%;
  opacity: 0.2;
  margin: 42px 0px 0px 0px;
  border: 0px;
`;

export const StyledRigthsDiv = styled.div<{ dark: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
  gap: 5px;
  opacity: 0.7;

  color: ${(props) =>
    props.dark ? props.theme.colors.WhiteSmoke : props.theme.colors.Dark};
  text-decoration: none;

  a {
    color: ${(props) =>
      props.dark ? props.theme.colors.WhiteSmoke : props.theme.colors.Dark};
    :hover {
      font-weight: bold !important;
      color: ${(props) =>
        props.dark ? props.theme.colors.WhiteSmoke : props.theme.colors.Dark};
      text-decoration: underline !important;
    }
  }
`;
