import React from "react";
import {
  SpaceBar,
  StyledRigthsDiv,
  Container,
} from "./footer-rights-reserved.styles";

interface IFooterRightsReserved {
  dark?: boolean;
}

export const FooterRightsReserved = ({ dark = false }: IFooterRightsReserved) => {
  let currentYear = new Date().getFullYear();

  return (
    <Container>
      <SpaceBar dark={dark} />
      <StyledRigthsDiv dark={dark}>
        <a
          href={process.env.REACT_APP_PRIVACY_POLICY}
          target="_blank"
          rel="noreferrer"
        >
          Política de Privacidade
        </a>
        <span>|</span>
        <span>
          {`© ${currentYear} Cox Automotive - Cox Pra Você. All Rights
            Reserved.`}
        </span>
      </StyledRigthsDiv>
    </Container>
  );
};
